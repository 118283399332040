*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  padding: 0;
  font-family: DroidArabicKufiRegular;
}

/* Scrollbar-start */
::-webkit-scrollbar {
   width: 5px;
 /* height: 130px; */
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #eef3eb 14%, #548438 64%);
  border-radius: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #548438 14%, #eef3eb 64%);
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 7px;
  box-shadow: inset 7px 7px 12px #f0f0f0;
}
/***************************************** Scrollbar-end *******************************************/

/***************************************** Arcgis-start *******************************************/

@import "@arcgis/core/assets/esri/themes/light/main.css";
.esri-button {
  align-items: center;
  background-color: #548438;
  border: 1px solid #eef3eb;
  color: #fff;
}
.esri-button:hover {
  align-items: center;
  background-color: #548438;
  border: 1px solid #eef3eb;
  color: #fff;
}

.esri-basemap-gallery__item--selected,
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected:hover,
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected:focus {
  cursor: auto;
  border-left-color: #548438;
  background-color: #eef3eb;
}
/***************************************** Arcgis-end *******************************************/
a {
  text-decoration: none;
  color: #080d06;
}

grammarly-desktop-integration {
  display: none;
}
